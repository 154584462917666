exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-recurrent-or-metastatic-nasopharyngeal-cancer-rm-npc-jsx": () => import("./../../../src/pages/about-recurrent-or-metastatic-nasopharyngeal-cancer-rm-npc.jsx" /* webpackChunkName: "component---src-pages-about-recurrent-or-metastatic-nasopharyngeal-cancer-rm-npc-jsx" */),
  "component---src-pages-get-updates-confirmation-jsx": () => import("./../../../src/pages/get-updates/confirmation.jsx" /* webpackChunkName: "component---src-pages-get-updates-confirmation-jsx" */),
  "component---src-pages-get-updates-index-jsx": () => import("./../../../src/pages/get-updates/index.jsx" /* webpackChunkName: "component---src-pages-get-updates-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loqtorzi-at-a-glance-jsx": () => import("./../../../src/pages/loqtorzi-at-a-glance.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-at-a-glance-jsx" */),
  "component---src-pages-loqtorzi-dosing-and-administration-jsx": () => import("./../../../src/pages/loqtorzi-dosing-and-administration.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-dosing-and-administration-jsx" */),
  "component---src-pages-loqtorzi-efficacy-first-line-treatment-jsx": () => import("./../../../src/pages/loqtorzi-efficacy-first-line-treatment.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-efficacy-first-line-treatment-jsx" */),
  "component---src-pages-loqtorzi-efficacy-nccn-guidelines-jsx": () => import("./../../../src/pages/loqtorzi-efficacy-nccn-guidelines.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-efficacy-nccn-guidelines-jsx" */),
  "component---src-pages-loqtorzi-efficacy-second-line-subsequent-treatment-jsx": () => import("./../../../src/pages/loqtorzi-efficacy-second-line-subsequent-treatment.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-efficacy-second-line-subsequent-treatment-jsx" */),
  "component---src-pages-loqtorzi-prescribing-information-jsx": () => import("./../../../src/pages/loqtorzi-prescribing-information.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-prescribing-information-jsx" */),
  "component---src-pages-loqtorzi-resources-for-hcp-and-patients-jsx": () => import("./../../../src/pages/loqtorzi-resources-for-hcp-and-patients.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-resources-for-hcp-and-patients-jsx" */),
  "component---src-pages-loqtorzi-safety-first-line-treatment-jsx": () => import("./../../../src/pages/loqtorzi-safety-first-line-treatment.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-safety-first-line-treatment-jsx" */),
  "component---src-pages-loqtorzi-safety-subsequent-line-treatment-jsx": () => import("./../../../src/pages/loqtorzi-safety-subsequent-line-treatment.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-safety-subsequent-line-treatment-jsx" */),
  "component---src-pages-loqtorzi-solutions-jsx": () => import("./../../../src/pages/loqtorzi-solutions.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-solutions-jsx" */),
  "component---src-pages-request-a-rep-confirmation-jsx": () => import("./../../../src/pages/request-a-rep/confirmation.jsx" /* webpackChunkName: "component---src-pages-request-a-rep-confirmation-jsx" */),
  "component---src-pages-request-a-rep-index-jsx": () => import("./../../../src/pages/request-a-rep/index.jsx" /* webpackChunkName: "component---src-pages-request-a-rep-index-jsx" */)
}

